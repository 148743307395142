<template>
  <!-- <div v-if="!isLoading"> -->
  <div class="mt-2">
    <div>
      <b-table
        :busy="isLoading"
        head-variant="light"
        :no-local-sorting="true"
        :items="items"
        :fields="getAllFields"
        :current-page="currentPage"
        per-page="0"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :sticky-header="height"
        no-border-collapse
        bordered
        responsive
        show-empty
        empty-text="Non ci sono informazioni da mostrare"
        small
        @sort-changed="sortingChanged"
        :tbody-tr-class="rowClass"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <base-icon name="loading" width="35" height="35" />
            <p>Operazione in corso...</p>
          </div>
        </template>
        <template #cell(customer)="{ item }" v-if="items.length">
          <span v-html="toCustomer(item)"></span>
        </template>
        <template #cell(insurance_policy)="{ item }" v-if="items.length">
          <span v-html="toPolicy(item)"></span>
        </template>
        <template #cell(book_tag)="{ item }" v-if="items.length">
          <span v-html="toBookTag(item)"></span>
        </template>
        <template #cell(company)="{ item }" v-if="items.length">
          <span v-html="toCompany(item)"></span>
        </template>
        <template #cell(broker)="{ item }" v-if="items.length">
          <span v-html="toBroker(item)"></span>
        </template>
        <template #cell(branch_risk)="{ item }" v-if="items.length">
          <span v-html="toBranchAndRisk(item)"></span>
        </template>

        <template #cell(rowSelector)="row" v-if="!isTableReadonly">
          <div class="action-buttons">
            <!-- isTableReadonly: {{ isTableReadonly }}<br />
            id: {{ row.item.id }}<br />hasChanged: {{ hasChanged(row.item.id)
            }}<br />isSelectedRow: {{ isSelectedRow(row.item.id) }}<br />
            checkAll: {{ checkAll }}<br />
            DISABLED:
            {{
              isTableReadonly ||
              !isSelectedRow(row.item.id) ||
              (isSelectedRow(row.item.id) && !hasChanged(row.item.id))
            }} -->
            <b-button
              size="sm"
              variant="lisaweb"
              @click="onChosen(row.item)"
              class="mt-1"
              title="Aggiorna riga"
              :disabled="
                isTableReadonly ||
                !hasChanged(row.item.id) ||
                !isChangeable(row.item)
              "
              >Aggiorna
            </b-button>
            <!-- :disabled="
                isTableReadonly ||
                !isSelectedRow(row.item.id) ||
                (isSelectedRow(row.item.id) && !hasChanged(row.item.id))
              " -->
            <!-- diasabilitato se tableReadonly O (non ha modifiche| non è selezionato) E non è calcolato con lisa E ha valori nulli  -->
          </div>
        </template>
        <template #cell(rowSelector2)="row" v-if="!isTableReadonly">
          <div class="action-buttons">
            <b-button
              size="sm"
              variant="lisaweb"
              @click="onChosen(row.item, 'rowSelector2')"
              class="mt-1"
              title="Elimina arretrato"
              :disabled="
                isTableReadonly ||
                !isOverdue(row.item) ||
                row.item.is_statement_excluded.value === 'Y'
              "
              >Elimina
            </b-button>
          </div>
        </template>
        <template #cell(deferred)="{ item }" v-if="items.length">
          <div>
            {{ toDeferred(item) }}
          </div>
        </template>
        <template #cell(saler_prov_purchase)="{ item }">
          <div v-if="!isCalcWithLisa || !isChangeable(item)">
            <base-currency
              name="saler_prov_purchase"
              v-model="item.saler_prov_purchase"
              :readonly="isTableReadonly || !isChangeable(item)"
              :options="{ currency: 'EUR', locale: 'it-IT' }"
              @keydown="
                !isTableReadonly && isChangeable(item) && onCellChange(item.id)
              "
            />
          </div>
          <div
            v-if="isCalcWithLisa && isChangeable(item)"
            :class="{
              coin: item.saler_prov_purchase != item.purchase_commission,
            }"
            :title="'Valore originale: ' + item.saler_prov_purchase"
          >
            <base-currency
              name="saler_prov_purchase"
              v-model="item.purchase_commission"
              :readonly="isTableReadonly || !isChangeable(item)"
              :options="{ currency: 'EUR', locale: 'it-IT' }"
              @keydown="
                !isTableReadonly && isChangeable(item) && onCellChange(item.id)
              "
            />
            <!-- <b-icon v-if="isCalcWithLisa && (item.saler_prov_purchase != item.purchase_commission)" icon="disc" aria-hidden="true" variant="lisaweb"></b-icon> -->
          </div>
          <!-- :readonly="isTableReadonly || (!item.insurance_ancillaries.length && item.various_accountings[0].id != 1)" -->
        </template>
        <template #cell(saler_prov_take)="{ item }">
          <div v-if="!isCalcWithLisa || !isChangeable(item)">
            <base-currency
              name="saler_prov_take"
              v-model="item.saler_prov_take"
              :readonly="isTableReadonly || !isChangeable(item)"
              :options="{ currency: 'EUR', locale: 'it-IT' }"
              @keydown="
                !isTableReadonly && isChangeable(item) && onCellChange(item.id)
              "
            />
          </div>
          <div
            v-if="isCalcWithLisa && isChangeable(item)"
            :class="{ coin: item.saler_prov_take != item.take_commission }"
            :title="'Valore originale: ' + item.saler_prov_take"
          >
            <base-currency
              name="saler_prov_take"
              v-model="item.take_commission"
              :readonly="isTableReadonly || !isChangeable(item)"
              :options="{ currency: 'EUR', locale: 'it-IT' }"
              @keydown="
                !isTableReadonly && isChangeable(item) && onCellChange(item.id)
              "
            />
            <!-- <b-icon v-if="isCalcWithLisa && (item.saler_prov_take != item.take_commission)" icon="disc" aria-hidden="true" variant="lisaweb"></b-icon> -->
          </div>
          <!-- :readonly="isTableReadonly || (!item.insurance_ancillaries.length && item.various_accountings[0].id != 1)" -->
        </template>
        <!-- // #1112 saler_fee_take -->
        <template #cell(saler_fee_take)="{ item }">
          <div v-if="!isCalcWithLisa || !isChangeable(item)">
            <base-currency
              name="saler_fee_take"
              v-model="item.saler_fee_take"
              :readonly="isTableReadonly || !isChangeable(item)"
              :options="{ currency: 'EUR', locale: 'it-IT' }"
              @keydown="
                !isTableReadonly && isChangeable(item) && onCellChange(item.id)
              "
            />
          </div>
          <div
            v-if="isCalcWithLisa && isChangeable(item)"
            :class="{ coin: item.saler_fee_take != item.insurer_fee }"
            :title="'Valore originale: ' + item.saler_fee_take"
          >
            <base-currency
              name="saler_fee_take"
              v-model="item.insurer_fee"
              :readonly="isTableReadonly || !isChangeable(item)"
              :options="{ currency: 'EUR', locale: 'it-IT' }"
              @keydown="
                !isTableReadonly && isChangeable(item) && onCellChange(item.id)
              "
            />
          </div>
        </template>

        <template #cell(tot_prod)="{ item }">
          {{
            toLocaleCurrency(
              !isCalcWithLisa || !isChangeable(item)
                ? parseFloat(
                    (
                      parseFloat(item.saler_prov_purchase) +
                      parseFloat(item.saler_prov_take) +
                      parseFloat(item.saler_fee_take)
                    ).toFixed(2)
                  )
                : parseFloat(
                    (
                      parseFloat(item.purchase_commission) +
                      parseFloat(item.take_commission) +
                      parseFloat(item.insurer_fee)
                    ).toFixed(2)
                  )
            )
          }}
        </template>

        <template #cell(confirmRow)="{ item }" v-if="hasChecks">
          <base-checkbox
            name="confirmed"
            class="check"
            v-model="item.is_confirmed.value"
            chk_val="Y"
            unchk_val="N"
            :disabled="isTableReadonly"
            @change="onChosen(item, 'confirm')"
          />
          <!-- :disabled="isTableReadonly || !isChangeable(item)"" -->
        </template>

        <template
          slot="bottom-row"
          slot-scope="data"
          v-if="includeBottomRow && items.length"
        >
          <td v-for="(field, i) in data.fields" :key="i" class="totalRow">
            <b
              >{{ i === 0 ? "Totale" : "" }}
              {{
                i !== 0 && field.key === "gross"
                  ? toLocaleCurrency(totalizeFields(items, field.key))
                  : null
              }}
              {{
                i !== 0 && field.key === "net"
                  ? toLocaleCurrency(totalizeFields(items, field.key))
                  : null
              }}
              {{
                i !== 0 && field.key === "agency_prov_purchase"
                  ? toLocaleCurrency(totalizeFields(items, field.key))
                  : null
              }}
              {{
                i !== 0 && field.key === "agency_prov_take"
                  ? toLocaleCurrency(totalizeFields(items, field.key))
                  : null
              }}
              {{
                i !== 0 && field.key === "saler_prov_purchase"
                  ? toLocaleCurrency(totalizeProvv(items, field.key))
                  : null
              }}
              <!-- totalizeFields(
                items,
                !isCalcWithLisa ? field.key : "purchase_commission"
              ) -->
              {{
                i !== 0 && field.key === "saler_prov_take"
                  ? toLocaleCurrency(totalizeProvv(items, field.key))
                  : null
              }}
              {{
                i !== 0 && field.key === "saler_fee_take"
                  ? toLocaleCurrency(totalizeProvv(items, field.key))
                  : null
              }}
              <!-- totalizeFields(
                items,
                !isCalcWithLisa ? field.key : "take_commission"
              ) -->
              {{
                i !== 0 && field.key === "tot_prod"
                  ? toLocaleCurrency(
                      totalizeProvv(items, [
                        "saler_prov_purchase",
                        "saler_prov_take",
                        "saler_fee_take",
                      ])
                    )
                  : null
              }}
              <!-- totalizeFields(items, [
                !isCalcWithLisa
                  ? "saler_prov_purchase"
                  : "purchase_commission",
                !isCalcWithLisa ? "saler_prov_take" : "take_commission",
              ]) -->
            </b>
          </td>
        </template>

        <!-- hasChecks: start -->
        <!-- slot1: check all / uncheck all header control -->
        <template
          v-slot:head(check)
          v-if="hasChecks && !isCheckExclusive && !isTableReadonly"
        >
          <!-- <span v-html="data.label"></span> -->
          <base-checkbox
            v-model="checkAll"
            name="all"
            class="check"
            :disabled="isTableReadonly"
            @change="onSelectAllRows"
          />
        </template>
        <!-- slot2: checkbox in each row -->
        <template #cell(check)="row" v-if="hasChecks">
          <!-- <b-form-checkbox
              v-if="!isTableReadonly"
              name="check"
              v-model="row.item.check"
              :disabled="isTableReadonly"
              @input="onSelectRow($event, row.index, row.item)"
            /> -->
          <base-checkbox
            v-if="!isTableReadonly"
            name="check"
            class="check"
            v-model="row.item.check"
            :disabled="isTableReadonly"
            @input="onSelectRow($event, row.index, row.item)"
          />
          <span v-else>{{ row.item.check ? "SI" : "NO" }}</span>
        </template>

        <!-- <template #cell(selezionato)="{ item }">
            <base-checkbox
              name="selected"
              v-model="item.selected"
              chk_val="Y"
              unchk_val="N"
              @input="onSelezionato($event, item)"
            />
          </template> -->

        <template #cell(actions)="row">
          <div class="action-buttons">
            <!-- details (modal) -->
            <b-button
              v-if="actions.includes('infomodal')"
              size="sm"
              variant="lisaweb"
              @click="openModal(row.item, row.index, $event.target)"
              class="mt-1 mr-1"
              title="Modale Dettagli"
            >
              <b-icon icon="eye"></b-icon>
            </b-button>

            <!-- details (accordion) -->
            <b-button
              v-if="actions.includes('details')"
              size="sm"
              variant="lisaweb"
              @click="row.toggleDetails"
              class="mt-1 mr-1"
              :title="row.detailsShowing ? 'Chiudi dettagli' : 'Apri dettagli'"
            >
              <!-- {{ row.detailsShowing ? "Hide" : "Show" }} Details -->
              <b-icon v-if="row.detailsShowing" icon="chevron-up"></b-icon>
              <b-icon v-else icon="chevron-down"></b-icon>
            </b-button>

            <!-- edit -->
            <b-button
              v-if="actions.includes('edit') && isVarAccByCode(row.item, 'CP')"
              size="sm"
              variant="lisaweb"
              @click="onEdit(row.item)"
              class="mt-1 mr-1"
              title="Modifica"
            >
              <b-icon icon="pencil-square"></b-icon>
            </b-button>

            <!-- destroy -->
            <b-button
              v-if="
                actions.includes('destroy') && isVarAccByCode(row.item, 'CP')
              "
              size="sm"
              variant="lisaweb"
              @click="onDestroy(row.item.id)"
              class="mt-1 mr-1"
              title="Elimina"
            >
              <b-icon icon="trash"></b-icon>
            </b-button>
          </div>
        </template>

        <template #row-details="row">
          <b-card>
            <!-- <ul>
            <li v-for="(value, key) in row.item" :key="key">
              {{ key }}: {{ value }}
            </li>
          </ul> -->
            <b-row class="mb-2" v-for="(value, key) in row.item" :key="key">
              <b-col sm="3" class="text-sm-right"
                ><b>{{ key | capitalize }}:</b></b-col
              >
              <b-col>{{ value }}</b-col>
            </b-row>
          </b-card>
        </template>
      </b-table>
    </div>

    <!-- Info modal -->
    <!-- <b-modal
      :id="infoModal.id"
      :title="infoModal.title"
      size="xl"
      ok-only
      ok-variant="lisaweb"
      header-bg-variant="lisaweb"
      scrollable
      @hide="resetInfoModal"
    >
      <pre>{{ infoModal.content }}</pre>
    </b-modal> -->
    <!-- </b-container> -->
  </div>
  <!-- <div v-else class="d-flex justify-content-center mb-3">
    <b-spinner label="Loading..." variant="warning"></b-spinner>
  </div> -->
</template>

<script>
import template from "./template";
import BaseIcon from "@/components/BaseIcon";
import BaseCheckbox from "@/components/form/BaseCheckbox";
// import BaseInput from "@/components/form/BaseInput";
import BaseCurrency from "@/components/form/BaseCurrency";
import { toLocaleCurrency } from "@/utils/strings";
import moment from "moment";
moment.locale("it");

const statusCSS = {
  purple: "effective-status-0", // Movimento vario modificabile (VIOLA)
  orange: "effective-status-1", // Registrazione esclusa dal rendiconto (ARANCIONE)
  pink: "effective-status-2", // Registrazione arretrata (ROSA)
  white: "effective-status-3", // Registrazione in intervallo date (NEUTRO)
};

export default {
  name: "SalesmenAccountingTable",
  extends: template,
  data() {
    return {
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      tableReadonly: false,
    };
  },
  props: {
    // statmod: {
    //   default: 0,
    // },
  },
  components: {
    BaseIcon,
    BaseCheckbox,
    // BaseInput,
    BaseCurrency,
  },
  computed: {
    // isChecked() {
    //   return (item) => {
    //     return item.selezionato;
    //   };
    // },
    hasChanged() {
      return (id) => {
        // console.log("hasChanged: ", id, this.$parent.$data.changedIds);
        return this.$parent.$data.changedIds
          ? this.$parent.$data.changedIds.includes(id)
          : false;
      };
    },
    isSelectedRow() {
      return (id) => {
        // console.log("isSelectedRow: ", id, this.selectedRows);
        return this.selectedRows ? this.selectedRows.includes(id) : false;
      };
    },
    isOverdue() {
      // arretrato:
      // se la riga ha data precedente al range di ricerca e non è in un rendiconto
      // (quelli già in altri rendiconti sono esclusi di default)
      return (item) => {
        return (
          moment(item.book_date).diff(
            moment(this.$parent.$data.filter.byCalendar.from)
          ) < 0
        );
      };
    },
    isCalcWithLisa() {
      // TODO: check the real value
      return this.$parent.$data.calculus_type == 1;
    },
    isChangeable() {
      // per i 2 input currency
      return (item) => {
        return (
          this.isVarAccByCode(item, "FC") || this.hasInsuranceAncillary(item)
        );
      };
    },
    isVarAccByCode() {
      // VariousAccounting by code
      return (item, code) => {
        // must return boolean
        return !!(
          item.various_accountings &&
          item.various_accountings.length > 0 &&
          item.various_accountings[0].code === code
        );
      };
    },
    isTableReadonly: {
      get() {
        return this.tableReadonly;
      },
      set(value) {
        this.tableReadonly = value;
      },
    },
  },
  mounted() {},

  methods: {
    toLocaleCurrency,
    totalTreasury(data, code, sign) {
      return parseFloat(
        data
          .filter(
            (e) =>
              e.treasury?.code === code &&
              (sign !== undefined
                ? sign === "+"
                  ? e.gross >= 0
                  : e.gross < 0
                : true)
          )
          .reduce(function (acc, obj) {
            return acc + parseFloat(obj.gross);
          }, 0)
          .toFixed(2)
      );
    },
    hasSomeTreasury(data, code) {
      return data.some((e) => e.treasury?.code === code);
    },
    hasNegativeTreasury(data, code) {
      return data.some((e) => e.treasury?.code === code && e.gross < 0);
    },
    hasInsuranceAncillary(data) {
      return !!(
        data.insurance_ancillaries && data.insurance_ancillaries.length
      );
    },
    toDeferred(data) {
      // REVIEW CHECK the condition is good!!!
      let ret = "SI";
      /* let hasSSS = this.hasSomeTreasury(data.entry_details, "SS");
      let hasNegSS = this.hasNegativeTreasury(data.entry_details, "SS");
      let totSS = this.totalTreasury(data.entry_details, "SS", "+");
      // aperti e parzialmente aperti
      if (hasSSS) {
        if (hasNegSS) {
          ret = totSS === 0 ? "" : "PARZ";
        } else {
          ret = "SI";
        }
      }
      return ret; */
      // SERGIO: è la stessa logica della index dei sospesi sul fatto che
      // hanno residuo maggiore di 0 ma minore del totale (parz), 0 ( "" ),
      // maggiore di 0 e uguale al totale sospeso ("Sì")

      // CHECK: verificare se debba essere come nella table BookEntriesDeferred (vedi rowClass)
      if (!data.entry_details || !data.entry_details.length) {
        ret = "";
      } else if (this.hasSomeTreasury(data.entry_details, "SS")) {
        if (this.totalTreasury(data.entry_details, "SS") === 0) {
          ret = "";
        } else {
          if (this.hasNegativeTreasury(data.entry_details, "SS")) {
            ret = "PARZ";
          }
        }
      } else {
        ret = "";
      }
      // Nella chiamata index 'summary' NON vengono inviati gli entry_detail nel payload
      // Utilizzare il campo 'status_deferred'
      if (data["status_deferred"]) {
        switch (data["status_deferred"]) {
          case "reduced":
            ret = "PARZ";
            break;
          case "yes":
            ret = "SI";
            break;
          case "no":
            ret = "";
            break;
          default:
            ret = "";
            break;
        }
      }

      return ret;
    },
    toCustomer(data) {
      // - se il movimento ha titolo oppure è vario di id 1, mostrare sempre il nome del contraente (insurance_anillaries.length)
      // - se il movimento è di tipo 2 (contabilità produttore) mostrare la descrizione fatta a mano  (title, various_accountings)
      // - se il movimento è di tipo 3 (Acconti) /(various_accountings)
      // - - se ha sia produttore che cliente mostrare “acconto cliente NOMINATIVO” (registry && broker)
      // - - se ha solo produttore mostrare “acconto produttore COD PRODUTTORE” (!registry && broker)
      let ret = "";
      switch (true) {
        case this.hasInsuranceAncillary(data) ||
          this.isVarAccByCode(data, "FC"):
          ret = this.toInfoData(data.registries[0], "registryfullname");
          break;

        case this.isVarAccByCode(data, "CP"):
          ret = data.various_accountings[0].title;
          break;

        case this.isVarAccByCode(data, "AC"):
          if (
            data.registries &&
            data.registries.length &&
            data.brokers &&
            data.brokers.length
          ) {
            ret = `acconto cliente ${this.toInfoData(
              data.registries[0],
              "registryfullname"
            )}`;
            break;
          }
          if (
            (!data.registries || !data.registries.length) &&
            data.brokers &&
            data.brokers.length
          ) {
            ret = `acconto produttore ${this.toInfoData(
              data.brokers[0],
              "broker"
            )}`;
            break;
            // ret = `acconto produttore ${this.toInfoData(data.brokers[0], "broker", 0)}` // passare status 0?
          }
          break;

        default:
          break;
      }
      // Fallback per valorizzare la colonna "Contraente/descrizione" per i "Movimenti vari modificabili" (viola)
      if (!ret) ret = data?.title;
      return ret;
    },
    toPolicy(data) {
      // - se il movimento ha titolo oppure è vario di id 1, mostrare sempre il numero polizza (occhio che può arrivare da titolo o dal movimento vario, come nella tabella dei fogli cassa)
      //   (various_accounings === 1 (I)|| insurance_ancillaries)()
      // -  se il movimento è di tipo 2 (contabilità produttore)
      // - - se ha lordo != 0 e prov prod inc = 0 mostrare “Registrazione premio”
      // - - se ha lordo = 0 e prov prod inc != 0 mostrare “Registrazione provvigione”
      // - - se ha entrambi != 0 mostrare "registrazione premio/provvigione”
      // - se il movimento è di tipo 3 (Acconti)
      // - - se ha sia produttore che cliente mostrare “acconto cliente”
      // - - se ha solo produttore mostrare “acconto produttore”
      let ret = "";
      switch (true) {
        case this.hasInsuranceAncillary(data) ||
          this.isVarAccByCode(data, "FC"):
          ret = this.hasInsuranceAncillary(data)
            ? // ? data.insurance_ancillaries[0].number
              data.insurance_ancillaries[0].insurance_policy?.number
            : data.various_accountings && data.various_accountings.length
            ? // ? data.various_accountings[0].number
              data.various_accountings[0].pivot.insurance_policy_number
            : "";
          break;

        case this.isVarAccByCode(data, "CP"):
          switch (true) {
            // prov prod inc ->? saler_prov_take
            case data.gross != 0 && data.saler_prov_take == 0:
              ret = "Registrazione premio";
              break;

            case data.gross == 0 && data.saler_prov_take != 0:
              ret = "Registrazione provvigione";
              break;

            case data.gross != 0 && data.saler_prov_take != 0:
              ret = "Registrazione premio/provvigione";
              break;

            default:
              break;
          }
          break;

        case this.isVarAccByCode(data, "AC"):
          if (
            data.registries &&
            data.registries.length &&
            data.brokers &&
            data.brokers.length
          ) {
            ret = `acconto cliente`;
            break;
          }
          if (
            (!data.registries || !data.registries.length) &&
            data.brokers &&
            data.brokers.length
          ) {
            ret = `acconto produttore`;
            break;
          }
          break;

        default:
          break;
      }
      return ret;
    },
    toBookTag(data) {
      let ret;
      if (!data.book_tags) {
        ret = "";
      } else if (
        this.hasInsuranceAncillary(data) ||
        this.isVarAccByCode(data, "FC")
      ) {
        ret = data.book_tags.map((e) => e.code).join(",");
      }
      return ret;
    },
    toCompany(data) {
      let ret;
      if (!data.insurer) {
        ret = "";
      } else if (
        this.hasInsuranceAncillary(data) ||
        this.isVarAccByCode(data, "FC")
      ) {
        ret = data.insurer.code;
      }
      return ret;
    },
    toBroker(data) {
      let ret;
      if (!data.brokers) {
        ret = "";
      } else {
        ret = data.brokers
          ? data.brokers.map((e) => e.attributables.BUID).join(",")
          : "";
      }
      return ret;
    },

    toBranchAndRisk(data) {
      // ramo e prodotto come su colonne foglio cassa (ma uniti in unica colonna
      const NOT_IMPORTED_BROKER = 2;
      if (data.various_accountings.length) {
        if (data.various_accountings[0].id === NOT_IMPORTED_BROKER) {
          return data.formatted_title;
        }
      }

      let b =
        data.insurance_ancillaries && data.insurance_ancillaries.length
          ? data.insurance_ancillaries[0].insurance_policy
            ? data.insurance_ancillaries[0].insurance_policy.insurance_risk
              ? `${data.insurance_ancillaries[0].insurance_policy.insurance_risk.risk_branch.code}-${data.insurance_ancillaries[0].insurance_policy.insurance_risk.code}-
                    ${data.insurance_ancillaries[0].insurance_policy.insurance_risk.risk_branch.formatted_title}`
              : ""
            : ""
          : data.various_accountings && data.various_accountings.length
          ? data.various_accountings[0].pivot.insurance_risk
            ? `${data.various_accountings[0].pivot.insurance_risk.risk_branch.code}-${data.various_accountings[0].pivot.insurance_risk.code}-
                  ${data.various_accountings[0].pivot.insurance_risk.risk_branch.formatted_title}
                  `
            : ""
          : "";
      let p =
        data.insurance_ancillaries && data.insurance_ancillaries.length
          ? data.insurance_ancillaries[0].insurance_policy
            ? data.insurance_ancillaries[0].insurance_policy.insurance_risk
                .formatted_title
            : ""
          : data.various_accountings && data.various_accountings.length
          ? data.various_accountings[0].pivot.insurance_risk
            ? data.various_accountings[0].pivot.insurance_risk.formatted_title
            : ""
          : "";
      return `${b} - ${p}`;
    },
    totalizeProvv(data, fields) {
      // personalizzazione della totalizeFields, per poter gestire la #774
      // devo stabilire quale campo usare nella somma tra:
      // - saler_prov_purchase/purchase_commission
      // - saler_prov_take/take_commission
      // - saler_fee_take/insurer_fee
      // il criterio è: this.isCalcWithLisa && this.isChangeable(row)
      // non lo potevo applicare nello slot bottom-row perché il test dipende dal singolo record
      let hasChecks = this.hasChecks;
      let tot = 0;
      if (typeof fields == "string") {
        fields = [fields];
      }
      fields.forEach((field) => {
        // let parts = field.split(".");
        tot += data
          .map((row) => {
            // somma solo se in presenza dei check, sono checkati
            // return !hasChecks || (hasChecks && row.check)
            //   ? +parts.reduce((acc, part) => acc && acc[part], row)
            //   : 0;
            if (!hasChecks || (hasChecks && row.check)) {
              switch (field) {
                case "saler_prov_purchase":
                  if (this.isCalcWithLisa && this.isChangeable(row)) {
                    field = "purchase_commission";
                  }
                  break;
                case "saler_prov_take":
                  if (this.isCalcWithLisa && this.isChangeable(row)) {
                    field = "take_commission";
                  }
                  break;
                case "saler_fee_take":
                  if (this.isCalcWithLisa && this.isChangeable(row)) {
                    field = "insurer_fee";
                  }
                  break;
                default:
                  break;
              }

              let parts = field.split(".");
              return +parts.reduce((acc, part) => acc && acc[part], row);
            } else return 0;
          })
          .reduce((sum, i) => {
            return sum + i;
          }, 0);
      });
      return tot;
    },
    // override the default function defined in template
    // onSelectRow(value, item) {
    //   console.debug(`checked: ${value}, id: ${item.id}`);
    //   // TODO call backend and save the checked status of this item

    //   // then()... fetch data
    //   // this.fetch()
    // },
    // info(item, index, button) {
    info(item, index, button) {
      // TODO: check if it's possible perform async calls here!!!
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      // this.$root.$emit("bv::show::modal", this.infoModal.id, button);
      this.$bvModal.show(this.infoModal.id, button);
    },
    // onSelezionato(value, item) {
    //   console.debug("onSelezionato");
    //   console.debug(value, item);
    //   this.$emit("selezionato", item);
    // },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    rowClass(item, type) {
      if (!item || type !== "row") return statusCSS["white"];
      // WARNING: check the parent's filter names!!!
      // in intervallo (default - nessuno dei casi seguenti)
      // arretrata (la data è antecedente al filtro da - perché nel filtro ho "includi arretrati")
      // esclusa (non ho checkato la row)
      // modificabile (insurance_ancillaries=[])
      let status;
      switch (true) {
        // case !item.insurance_ancillaries.length && item.various_accountings[0].id != 1:
        // case !item.insurance_ancillaries.length &&
        //   item.various_accountings.length &&
        //   item.various_accountings[0].code != "FC":
        //   // item.various_accountings[0].code === "CP":
        //   status = "purple";
        //   break;
        case this.isTableReadonly && !this.selectedRows.includes(item.id):
          status = "orange";
          break;
        case !this.isChangeable(item): // #925
          status = "purple";
          break;
        case this.$parent.$data.filter &&
          moment(item.book_date).diff(
            moment(this.$parent.$data.filter.byCalendar.from)
          ) < 0:
          status = "pink";
          break;
        default:
          status = "white";
      }
      return statusCSS[status];
    },
  },
};
</script>
<style lang="scss" scoped>
// :deep(.check) {
//   width: 1px;
//   // padding-left: 10px;
// }
:deep(.check .form-group) {
  margin-bottom: unset !important;
}
.totalRow {
  text-align: right;
}
/* nelle table con form input, toglie il margine */
:deep(table .form-group) {
  margin-bottom: 0rem !important;
}
/* VIOLA */
:deep(.effective-status-0 td:nth-child(4)) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(125, 0, 125, 0.5) 100%
  );
}
/* ARANCIONE */
:deep(.effective-status-1 td:nth-child(4)) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(253, 173, 0, 0.4) 100%
  );
}
/* ROSA */
:deep(.effective-status-2 td:nth-child(4)) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 0, 0, 0.3) 100%
  );
}
/* NEUTRO */
:deep(.effective-status-3 td:nth-child(4)) {
  background: rgb(255, 255, 255);
}

:deep(.coin) {
  box-shadow: 0px 0px 8px 2px #f9991e;
  border: 1px solid #ffc107;
}
</style>
